<template>
  <!-- <div style="height:calc(100vh - 40px);overflow-y:auto"> -->
    <div style="height:calc(100vh - 40px);overflow-y:auto">
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <!-- <el-col :lg="5" :md="24">
            <el-form-item label="商户名称：">
              <el-select
                  clearable
                  v-model="searchForm.merchantName"
                  placeholder="状态"
                  class="w100"
                >
                  <el-option
                    v-for="item in merchantOptions"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :lg="5" :md="24">
            <el-form-item label="手机号码：">
              <el-input
                clearable
                v-model="searchForm.loginName"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="教师姓名：">
              <el-input
                clearable
                v-model="searchForm.name"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <template v-if="advanced">
            <!-- <el-col :lg="5" :md="24">
              <el-form-item label="性别">
                <el-input
                  clearable
                  v-model="searchForm.sex"
                  placeholder="请输入"
                  class="w100"
                ></el-input>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :lg="5" :md="24">
              <el-form-item label="性别：">
                <el-select
                  clearable
                  v-model="searchForm.sex"
                  placeholder="性别"
                  class="w100"
                >
                  <el-option
                    v-for="item in options1"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :lg="5" :md="24">
              <el-form-item label="工号：">
                <el-input
                  clearable
                  v-model="searchForm.num"
                  placeholder="请输入"
                  class="w100"
                ></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :lg="5" :md="24">
              <el-form-item label="状态：">
                <el-select
                  clearable
                  v-model="searchForm.status"
                  placeholder="状态"
                  class="w100"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </template>
          <!-- <search-query
            @updateView="updateView"
            @clearQuery="clearQuery"
            @searchQuery="$emit('search', searchForm)"
          ></search-query> -->
          <span>
            <el-button @click="resetForm">重置</el-button>
            <el-button type="primary" @click="getList">查询</el-button>
          </span>
        </el-row>
      </div>
    </el-form>

    <!-- 表格 -->
    <el-card shadow="never" style="overflow-y: scroll; height: calc(100% - 80px)">
      <div slot="header" class="flex" style="align-items: center">
        <span>商户教师数据</span>
        <div class="flex_1"></div>
        <el-button type="primary" size="mini" @click="openAddModal"
          >添加</el-button
        >
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table-bar fixed :static="true">
        <el-table
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
          :data="tableData"
          style="max-height:calc(100vh - 270px);overflow-y:auto"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '状态'">
                <el-popconfirm
                  @onConfirm="confirmStatus(scope.row)"
                  @confirm="confirmStatus(scope.row)"
                  :title="`确定要${
                    statusOptions[scope.row.state == '1' ? '3' : '1']
                  }该数据吗？`"
                >
                  <el-button type="text" slot="reference">
                    {{ statusOptions[scope.row.state] }}
                  </el-button>
                </el-popconfirm>
              </template>
              <template v-else-if="item.label === '操作'">
                <!-- <el-button
                  style="margin-left: 8px"
                  type="text"
                  @click="openDetailModal(scope.row)"
                  >查看</el-button
                > -->
                <el-button
                  style="margin-left: 8px"
                  type="text"
                  @click="openEditModal(scope.row)"
                  >编辑</el-button
                >
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>

      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
    <AddModal ref="add" title="添加教师" type="primary" @submit="addMember"/>
    <EditModal ref="edit" title="编辑教师" :item="item" @submit="editMember" />
    <!-- <DetailModal ref="detail" title="查看" /> -->
  </div>
</template>

<script>
import {
  getEmployeeList,
  addMerchantEmployee,
  deleteEmployee,
  forbiddenEmployee,
  editEmployee,
} from "@/api/member/merchant";
import TextButton from "@/components/TextButton";
import SearchQuery from "@/components/SearchQuery";
import AddModal from "@/views/member/AddModalteacher";
import EditModal from "@/views/member/EditModal";
import DetailModal from "@/views/member/DetailModal";
// 默认展示列

const baseColSetting = [
  {
    label: "姓名",
    prop: "name",
    state: true,
    align: "left",
    width: "100",
  },
  {
    label: "手机号",
    prop: "tel",
    state: true,
    align: "center",
    // sortable: true,
    width: "180",
  },
  // {
  //   label: "工号",
  //   prop: "number",
  //   state: true,
  //   // sortable: true,
  //   align: "center",
  //   width: "150",
  // },
  {
    label: "类型",
    prop: "type",
    state: true,
    // sortable: true,
    align: "center",
    width: "150",
  },
  // {
  //   label: "性别",
  //   prop: "sex",
  //   state: true,
  //   // sortable: true,
  //   align: "center",
  //   width: "150",
  // },
  {
    label: "状态",
    prop: "state",
    state: true,
    // sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "创建时间",
    prop: "createTime",
    state: true,
    sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "130",
  },
];
export default {
  components: {  AddModal, EditModal, DetailModal, TextButton, SearchQuery },
  data() {
    return {
      merchantOptions:[],
      item:{},
      advanced: false,
      size: "small",
      searchForm: {
        loginName: null,
        name: null,
        telNumber: null,
        status: null,
        merchantName: null,
        type:'员工',
        num:null
      },
      queryParam: {
        seleShow: "list",
        time: "",
      },
      // searchForm: {
      //   loginName: null,
      //   name: null,
      //   tel: null,
      //   state: null,
      //   merchantName: null,
      //   type: null,
      // },
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      tableKey: 1,
      statusOptions: {
        1: "正常",
        // "2":"删除",
        3: "禁用",
      },
      options: [
        {
          value: "正常",
          label: "正常",
        },
        // {
        //   value: '2',
        //   label: '删除',
        // },
        {
          value: "禁用",
          label: "禁用",
        },
      ],
      // options1:[
      //   {
      //     value: "男",
      //     label: "男",
      //   },
      //   {
      //     value: "女",
      //     label: "女",
      //   },
      // ],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    /**
     * 获取列表
     */

    async getList(){
        let responseData = await getEmployeeList({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        merchantId:this.$route.query.merchantId,
        ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    // async getListHandler() {

    //   let responseData = await getEmployeeList({
    //     pageNum: this.pageInfo.pageNum,
    //     pageSize: this.pageInfo.pageSize,
    //     needCount: this.pageInfo.needCount,
    //     ...this.searchForm,
    //   });
    //   console.log(responseData,'shuju ')
    //   if (responseData && responseData.state == "success") {
    //     this.tableData = responseData.body.list;
    //     this.pageInfo.total = responseData.body.total;
    //   }
    // },
    /**
     * 获取详情
     */
    getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    confirmStatus(item) {
      const { id, state } = item;
      forbiddenEmployee({
        id,
        status: state == "1" ? 3 : 1,
      }).then(() => {
        this.getList();
      });
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getList();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getList();
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getList();
    },
    /**
     * 查询获取列表
     */
    getListHandler() {},
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        loginName: null,
        name: null,
        tel: null,
        state: null,
      };
      // this.getListHandler();
    },
    /**
     * 查看
     */
    handleClick(item) {},
    /**
     * 打开添加弹窗
     */
    openAddModal() {
      this.$refs.add.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.add.initData();
      });
    },
    // updateView(data) {
    //   this.advanced = data;
    // },
    // clearQuery() {
    //   (
    //     this.pageInfo.pageNum = 1,
    //     this.pageInfo.pageSize = 10,
    //     this.searchForm = {
    //     loginName: null,
    //     name: null,
    //     tel: null,
    //     state: null,
    //   }),
    //     this.$emit("clearQuery");
    // },
    /**
     * 添加成员
     */
    async addMember(value) {
      const { name, telNumber,  pwd, loginName, state } = value;
      const response = await addMerchantEmployee({ name, telNumber, pwd, loginName, state });
      if (response.state == "success") {
        // this.pageInfo.pageNum = 1;
        // this.pageInfo.pageSize = 10;
        this.$message.success("添加成功");
        this.getList();
        this.$refs.add.dialogVisible = false;
      } else {
        this.$message.warning("添加失败");
      }
    },
    async editMember(value) {
      const { id, name, telNumber, pwd, state ,merchantName,loginName} = value;
      const response = await editEmployee({
        id,
        name,
        telNumber,
        pwd,
        state,
        merchantName
      });
      if (response.state == "success") {
        this.$message.success("编辑成功");
        //   this.pageInfo.pageNum = 1;
        //   this.pageInfo.pageSize = 10;
        this.getList();
        this.$refs.edit.dialogVisible = false;
      } else {
        this.$message.warning("编辑失败");
      }
    },
    /**
     * 打开详情
     */
    openDetailModal(item) {
      this.$refs.detail.initData(item);//赋值回显
      this.$nextTick(() => {
        this.$refs.detail.drawer = true;//打开抽屉
      });
    },
    /**
     * 打开编辑弹窗
     */
    openEditModal(item) {
      // TODO 添加获取详情
      this.item = item || {};
      this.$nextTick(() => {
        this.$refs.edit.dialogVisible = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}
@import "../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
@import "../../style/container";
</style>